import React, { Component } from 'react';
import Axios from 'axios';
class Contact extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      description: '',
      issueTitle: '',
      steps: ['', ''],
      attachments: [],
      totalSize: 0,
      maxSize: 1000 * 100000,
      images: [],
      cmp: 'tm'
    }
  }
  sendInfo = () => {
    Axios.post('https://givvy-backend-site-dev.herokuapp.com/sendFeedbackContactUs', this.state).then((res) => {
      if (res.data.statusCode !== 200) {
        alert(res.data.statusText)
      } else {
        alert(`Thank you for contacting us! We will take a look at your request and answer you as soon as possible!`)
        window.location.reload()
      }
    })
  }
  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  browse = () => {
    document.getElementById('file-up').click()
  }
  addSteps = () => {
    let steps1 = this.state.steps
    steps1.push('')
    this.setState({ steps: steps1 });
  }
  changeSteps = (e, index) => {
    let steps = this.state.steps
    steps[index] = e.target.value
    this.setState({ steps: steps });
  }
  onChangeFiles = (e) => {
    let files = e.target.files
    this.onLoad(files, 0, [])
  }
  onLoad = async (files, count, base64) => {

    return this.getBase64(files[count]).then((res) => {
      base64.push(res)
      this.setState({ totalSize: this.state.totalSize + files[count].size })
      if (files.length <= count + 1) {
        this.setState({ attachments: base64 })
      } else {
        if (this.state.totalSize < this.state.maxSize) {
          this.onLoad(files, count + 1, base64)
        } else {
          this.setState({ totalSize: 0, attachments: [] })
          alert(`You exceed 10MB please select small images`)
        }
      }
    })
  }
  renderImages = () => {
    return this.state.attachments.map((value) => {
      return (<div className="col-lg-2" style={{ border: '1px solid black', borderRadius: '7.5px', padding: '1%' }}>
        <img style={{ width: '100%', borderRadius: '7.5px' }} alt="" src={value}></img>
      </div>)
    })
  }
  renderSteps = () => {
    const steps = this.state.steps
    return steps.map((value, index) => {
      return (
        <div className="d-flex justify-content-start mt-1">
          <p className="m-0 align-self-center" style={helpers.bold2('#313131', 15, { width: "15px" })}>
            {index + 1}
          </p>
          <input onChange={(e) => { this.changeSteps(e, index) }} className="form-control" placeholder={`Step ${index + 1}`}></input>
        </div>
      )
    })
  }


  render() {

    return (
      <div className="container my-5">
        <div className="main-container">
          <div className="d-flex justify-content-start">
            <img className="align-self-center mx-1" src="/logo-34.png" style={{ width: "35px", height: "25px" }} alt=""></img>
            <p className="m-0 align-self-center mx-1" style={helpers.bold2('#313131', 25)}>Hi dear <span style={helpers.bold('#313131')}>Player</span>,</p>
          </div>
          <div className="d-flex justify-content-start">
            <p className="m-0 align-self-center mx-1" style={helpers.bold2('#313131', 25)}>
              Please describe in details what is your issue and we will try to fix it as soon as we can
            </p>
          </div>
          <div className="d-flex justify-content-start mt-3">
            <p className="required m-0 align-self-center mx-1" style={helpers.bold2('#313131', 15)}>
              Your email
            </p>
          </div>
          <div className="d-flex justify-content-start mt-1">
            <input onChange={(e) => { this.setState({ email: e.target.value }) }} className="form-control" placeholder="Enter your email"></input>
          </div>
          <div className="d-flex justify-content-start mt-3">
            <p className="required m-0 align-self-center mx-1" style={helpers.bold2('#313131', 15)}>
              Issue title
            </p>
          </div>
          <div className="d-flex justify-content-start mt-1">
            <input onChange={(e) => { this.setState({ issueTitle: e.target.value }) }} className="form-control" placeholder="Please describe your issue in one structured sentence"></input>
          </div>
          <div className="d-flex justify-content-start mt-3">
            <p className="required m-0 align-self-center mx-1" style={helpers.bold2('#313131', 15)}>
              Detailed description
            </p>
          </div>
          <div className="d-flex justify-content-start mt-1">
            <textarea onChange={(e) => { this.setState({ description: e.target.value }) }} className="form-control" placeholder="Describe in details what is your issue"></textarea>
          </div>
          <div className="d-flex justify-content-start mt-3">
            <p className="required m-0 align-self-center mx-1" style={helpers.bold2('#313131', 15)}>
              Steps to reproduce
            </p>
          </div>
          <div id="steps">
            {this.renderSteps()}
          </div>
          <div onClick={() => { this.addSteps() }} className="d-flex justify-content-center mt-3">
            <p className="m-0 align-self-center" style={helpers.bold2('#313131', 15, { cursor: "pointer" })}>
              + Add more steps
            </p>
          </div>
          <div className="d-flex justify-content-start mt-3">
            <p className="required m-0 align-self-center mx-1" style={helpers.bold2('#313131', 15)}>
              Attach files
            </p>
          </div>
          <div className="d-flex justify-content-start">
            <p className="m-0 align-self-center ml-2 pl-1" style={helpers.bold2('#313131', 14)}>
              You can upload up to 3 files - images in .PNG, .JPG, .JPEG up to <b style={helpers.bold('#313131', 14)}>10MB</b>
            </p>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <div className="d-flex justify-content-center" style={{ borderRadius: '23px', backgroundColor: '#fcfcfc', width: '250px', height: '150px', border: 'dashed 0.5px #707070' }}>
              <button onClick={() => { this.browse() }} className="btn" style={helpers.bold1('white', 14, { backgroundColor: '#a5a5a5', margin: '55px' })}>Browse</button>
              <input
                id="file-up"
                type="file"
                multiple
                onChange={this.onChangeFiles}
                onClick={(e) => { e.target.value = null }}
                style={{ display: 'none' }}
              />
            </div>
          </div>
          {this.state.attachments.length > 0 ?
            <div className="d-flex justify-content-center mt-5">
              <button className="btn btn-danger" onClick={() => { this.setState({ attachments: [], totalSize: 0 }) }}>Delete All</button>
            </div> : null}
          <div className="d-flex justify-content-center mt-5">
            {this.state.attachments.length > 0 ? <>

              <div className="row">
                {this.renderImages()}
              </div>
            </> : null}
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button onClick={() => { this.sendInfo() }} className="btn px-5" style={helpers.bold1('white', 14, { backgroundImage: "linear-gradient(103deg, #5d75d8 2%, #a65dd3 103%)", borderRadius: '10px' })}>Submit</button>
          </div>
        </div>
      </div>
    );
  }
}
export default Contact;


const helpers = {
  bold: (color, size, options) => {
    let style = {
      color: color,
      fontFamily: 'Poppins-Black',
      fontSize: `${size}px`
    }
    style = { ...style, ...options }
    return style
  },
  bold1: (color, size, options) => {
    let style = {
      color: color,
      fontFamily: 'Poppins-Bold',
      fontWeight: '600',
      fontSize: `${size}px`,
    }
    style = { ...style, ...options }
    return style
  },
  bold2: (color, size, options) => {
    let style = {
      color: color,
      fontFamily: 'Poppins-Medium',
      fontSize: `${size}px`,
    }
    style = { ...style, ...options }
    return style
  },
  bold3: (color, size, options) => {
    let style = {
      color: color,
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      fontSize: `${size}px`,
    }
    style = { ...style, ...options }
    return style
  },
  bold4: (color, size, options) => {
    let style = {
      color: color,
      fontFamily: 'Poppins-Black-2',
      fontSize: `${size}px`,
    }
    style = { ...style, ...options }
    return style
  }
}

