import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Navbar.css'
class CustomNavbar extends Component {

  render() {
    //return(<div className="nav-logo"><img alt="jpg" id="logo" src="/Group 17.png"></img></div>)

    return (
      <nav className="nav">

        <div className="logo">
          <a href="#"><img alt="jpg" id="logo" src="/Group 17.png"></img></a>
        </div>
        <div className="main_list" id="mainListDiv">
          <ul>
            <li><a href="/privacy-policy.pdf">Privacy Policy</a></li>
            <li><a href="/general-terms-and-conditions.pdf">Terms and Conditions</a></li>
            {/* <li><a href="/contact">Contact us</a></li> */}
          </ul>
        </div>
        <div className="media_button">
          <button className="main_media_button" id="mediaButton">
            <span></span>
            <span></span>
            <span></span>
          </button>

        </div>
      </nav>
      // <Navbar bg="primary" expand="lg">
      //   <Navbar.Brand className="logo2" href="#home"><img alt="jpg" id="logo" src="/Group 17.png"></img></Navbar.Brand>
      //   <Navbar.Toggle aria-controls="basic-navbar-nav" />
      //   <Navbar.Collapse id="basic-navbar-nav">
      //     <Nav className="mr-auto">
      //       <Nav.Link href="/home">Home</Nav.Link>
      //       <Nav.Link href="/generator">How it works</Nav.Link>
      //       <Nav.Link href="/generator">Contact us</Nav.Link>
      //       <Nav.Link href="/generator">Terms of Service</Nav.Link>
      //     </Nav>

      //   </Navbar.Collapse>
      // </Navbar>
    );
  }
}
export default CustomNavbar;