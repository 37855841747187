import React, { Component } from 'react';
import axios from 'axios';
import './Winners.scss'

// import './LeaderBoard.css'

var user = {
    userId: '5dfe5030a0e1e23837500702',
    "type": 2,
    "offset": 1,
    "limit": 20000
};
const path = "https://giveybackend-prod.herokuapp.com/";
const pathEarnMoney = "https://earnmoney-prod.herokuapp.com/"
const pathGameAndEarn = "https://gameandearn-prod.herokuapp.com/"
const pathCashFun = "https://cashfun-prod.herokuapp.com/"
const pathSite = "https://givvy-backend-site-payments.herokuapp.com/"

class Winners extends Component {

    constructor() {
        super();
        this.state = {
            pending: [],
            sended: [],
            waitingToBeSend: [],
            isLoading: true,
            giveawaysThatNeedDrawUrl: [],
            drawUrl: null,
            withdraw: [],
            withdrawEarnMoney: [],
            withdrawGameAndEarn: [],
            withdrawCashFun: [],
            withdrawSite: []
        }
    }


    componentDidMount() {
        console.log(`compoe`)
        // axios.post(`${path}giveawaysThatNeedDrawUrl`)
        //     .then(res => {
        //         console.log(`res`)
        //         console.log(res)
        //         let giveawaysThatNeedDrawUrl = res.data.result;
        //         this.setState({ giveawaysThatNeedDrawUrl });
        //     })
        //     .catch(console.error)
        //     console.log(`compoe`)
        axios.get(`${path}getPendingGiveaways`)
            .then(res => {
                console.log(`res`)
                console.log(res)
                this.setState({ pending: res.data.result.pending, sended: res.data.result.sended, waitingToBeSend: res.data.result.waitingToBeSend, isLoading: false })
            })
            .catch(console.error)
        this.getInfoWithdraw();
        this.getInfoWithdrawEarnMoney();
        this.getInfoWithdrawGameAndEarn();
        this.getInfoWithdrawCashFun();
        this.getInfoWithdrawSite();
        //this.getUserInfo('asdas')

        // axios.post(`${path}getRanking`, user)
        //   .then(res => {
        //     const ranking = res.data.result.rankings;
        //     this.setState({ ranking });
        //   })
    }
    async getInfoWithdraw() {
        await axios.post(`${path}getWithdrawHistoryForAdminPanel`)
            .then(res => {
                console.log(res);
                this.setState({ withdraw: res.data.result, isLoading: false })
            })
            .catch(console.error)
    }
    async getInfoWithdrawEarnMoney() {
        await axios.post(`${pathEarnMoney}getWithdrawHistoryForAdminPanel`)
            .then(res => {
                console.log(res);
                this.setState({ withdrawEarnMoney: res.data.result, isLoading: false })
            })
            .catch(console.error)
    }
    async getInfoWithdrawGameAndEarn() {
        await axios.post(`${pathGameAndEarn}getWithdrawHistoryForAdminPanel`)
            .then(res => {
                console.log(res);
                this.setState({ withdrawGameAndEarn: res.data.result, isLoading: false })
            })
            .catch(console.error)
    }
    async getInfoWithdrawCashFun() {
        await axios.post(`${pathCashFun}getWithdrawHistoryForAdminPanel`)
            .then(res => {
                console.log(res);
                this.setState({ withdrawCashFun: res.data.result, isLoading: false })
            })
            .catch(console.error)
    }
    async getInfoWithdrawSite() {
        await axios.post(`${pathSite}getWithdrawHistoryForAdminPanel`)
            .then(res => {
                console.log(res);
                this.setState({ withdrawSite: res.data.result, isLoading: false })
            })
            .catch(console.error)
    }
    getUserInfo = async (yu) => {

        let pending = this.state.pending
        for (let index = 0; index < pending.length; index++) {
            let element = pending[index];
            let userId = element.winnerId
            let params = {
                userId: userId
            }
            await axios.post(`${path}getUser`, params)
                .then(res => {
                    pending[index].email = res.data.result.email
                    pending[index].telephone = res.data.result.telephone
                    pending[index].adress = res.data.result.adress
                })

        }
        this.setState({ pending: pending })

        let sended = this.state.sended
        for (let index = 0; index < sended.length; index++) {
            let element = sended[index];
            let userId = element.winnerId
            let params = {
                userId: userId
            }
            await axios.post(`${path}getUser`, params)
                .then(res => {
                    sended[index].email = res.data.result.email
                    sended[index].telephone = res.data.result.telephone
                    sended[index].adress = res.data.result.adress
                })

        }
        this.setState({ sended: sended })
        let waitingToBeSend = this.state.waitingToBeSend
        for (let index = 0; index < waitingToBeSend.length; index++) {
            let element = waitingToBeSend[index];
            let userId = element.winnerId
            let params = {
                userId: userId
            }
            await axios.post(`${path}getUser`, params)
                .then(res => {
                    waitingToBeSend[index].email = res.data.result.email
                    waitingToBeSend[index].telephone = res.data.result.telephone
                    waitingToBeSend[index].adress = res.data.result.adress
                })

        }
        this.setState({ waitingToBeSend: waitingToBeSend, isLoading: false })
    }

    sendPendingReward = async (giviId) => {
        let check = window.confirm("are you sure");
        if (check == true) {
            let req = {
                giveawayId: giviId
            }
            await axios.post(`${path}sendPendingReward`, req)
                .then(res => {
                    window.location.href = document.URL;
                })
            alert(`Успешно испратен подарак`)
            window.location.href = document.URL;
        }
        else {

        }
        let req = {
            giveawayId: giviId
        }


    }
    chnaheScroll = () => {
        setTimeout(() => {
            window.scrollBy({
                top: -190,
                left: 0,
                behavior: 'smooth'
            });
        }, 1000)
    }
    getgivieswithoutURL() {
        axios.post(`${path}giveawaysThatNeedDrawUrl`)
            .then(res => {
                let giveawaysThatNeedDrawUrl = res.data.result;
                this.setState({ giveawaysThatNeedDrawUrl });
            })
    }
    confirmDraw = (id) => {
        if (id && this.state.drawUrl != null) {
            let request = {
                giveawayId: id, drawUrl: this.state.drawUrl
            }
            axios.post(`${path}setDrawUrl`, request)
                .then(res => {
                    this.setState({ drawUrl: null })
                    this.getgivieswithoutURL();
                    this.clearInputs();
                    alert(`Успешно добави URL към това ${id}`);
                })
        }

    }
    setUrl = url => {

        this.setState({ drawUrl: url.target.value });
    }
    clearInputs() {
        let inputs = document.getElementsByClassName('inputUrl');
        for (let index = 0; index < inputs.length; index++) {
            let element = inputs[index];
            element.value = "";
        }
    }

    sendToWithdraw = async (id, username, paypalEmail) => {
        let check = window.confirm(`Сигурен ли си че си изпратил парите на ${username} с EMAIL ${paypalEmail}?`);
        if (check == true) {
            let req = {
                "withdrawId": id
            }
            await axios.post(`${path}markWithdrawAsSend`, req)
                .then(res => {
                    alert(`Успешно испратен подарак`)
                })

            this.getInfoWithdraw();
        }
    }
    sendToWithdrawEarnMoney = async (id, username, paypalEmail) => {
        let check = window.confirm(`Сигурен ли си че си изпратил парите на ${username} с EMAIL ${paypalEmail}?`);
        if (check == true) {
            let req = {
                "withdrawId": id
            }
            await axios.post(`${pathEarnMoney}markWithdrawAsSend`, req)
                .then(res => {
                    alert(`Успешно испратен подарак`)
                })

            this.getInfoWithdrawEarnMoney();
        }
    }
    sendToWithdrawGameAndEarn = async (id, username, paypalEmail) => {
        let check = window.confirm(`Сигурен ли си че си изпратил парите на ${username} с EMAIL ${paypalEmail}?`);
        if (check == true) {
            let req = {
                "withdrawId": id
            }
            await axios.post(`${pathGameAndEarn}markWithdrawAsSend`, req)
                .then(res => {
                    alert(`Успешно испратен подарак`)
                })

            this.getInfoWithdrawGameAndEarn();
        }
    }
    sendToWithdrawCashFun = async (id, username, paypalEmail) => {
        let check = window.confirm(`Сигурен ли си че си изпратил парите на ${username} с EMAIL ${paypalEmail}?`);
        if (check == true) {
            let req = {
                "withdrawId": id
            }
            await axios.post(`${pathCashFun}markWithdrawAsSend`, req)
                .then(res => {
                    alert(`Успешно испратен подарак`)
                })

            this.getInfoWithdrawCashFun();
        }
    }
    sendToWithdrawSite = async (id, username, paypalEmail) => {
        let check = window.confirm(`Сигурен ли си че си изпратил парите на ${username} с EMAIL ${paypalEmail}?`);
        if (check == true) {
            let req = {
                "withdrawId": id
            }
            await axios.post(`${pathSite}markWithdrawAsSend`, req)
                .then(res => {
                    alert(`Успешно испратен подарак`)
                })

            this.getInfoWithdrawSite();
        }
    }
    sendToWithdrawReturn = async (id, username, paypalEmail, currentPath) => {
        let check = window.confirm(`Сигурен ли си че искаш да върнеш парите на ${username} с EMAIL ${paypalEmail} в акаунта му?`);
        if (check == true) {
            let req = {
                "withdrawId": id
            }
            await axios.post(`${currentPath}markWithdrawAsReturned`, req)
                .then(res => {
                    alert(`Успешно върнат подарак`)
                })
            if (currentPath === path) {
                this.getInfoWithdraw();
            } else if (currentPath === pathCashFun) {
                this.getInfoWithdrawCashFun();
            } else if (currentPath === pathEarnMoney) {
                this.getInfoWithdrawEarnMoney();
            } else if (currentPath === pathSite) {
                this.getInfoWithdrawSite();
            } else if (currentPath === pathGameAndEarn) {
                this.getInfoWithdrawGameAndEarn();
            } else {
                alert(`не намерен бекенд`)
            }

        }
    }
    copyClip(id, email) {
        const copy = require('clipboard-copy')
        copy(email)
        let currentCopy = document.getElementById(id)
        currentCopy.style.color = 'orange'
        // currentCopy.setSelectionRange(0, 99999); /* For mobile devices */
        // navigator.clipboard.writeText(currentCopy.value)

    }
    renderImageWithdraw(type) {
        console.log(type)
        switch (type) {
            case 1:
                return (<td><img src="/paypal.png"></img></td>)
            case 2:
                return (<td><img src="/coinbase.png"></img></td>)
            case 3:
                return (<td><img src="/binance.png"></img></td>)
            case 4:
                return (<td><img src="/amazon.png"></img></td>)
            case 5:
                return (<td><img src="/airtm-w.png"></img></td>)
            case 6:
                return (<td><img src="/payeer-w.png"></img></td>)
            case 7:
                return (<td><img src="/litecoin-w.png"></img></td>)
            case 8:
                return (<td><img src="/perfectMoney-w.png"></img></td>)
            case 9:
                return (<td><img src="/advcash-w.png"></img></td>)
            default:
                return (<td><img src="/paypal.png"></img></td>)
        }
    }
    render() {

        if (!this.state.isLoading) {
            let pending = this.state.pending.map((value, index) => {
                //let infoOfUser = await this.getUserInfo(value.winnerId);
                return (
                    <tr>
                        <td><img src={value.images[0]} alt="" /></td>
                        <td>{value.winnerName}</td>
                        <td>{value.phone ? value.phone : ""}</td>
                        <td>{value.adress ? value.adress : ""}</td>
                        <td>{value.IBAN ? value.IBAN : ""}</td>
                        <td>{value.epayKIN ? value.epayKIN : ""}</td>
                        <button onClick={() => this.sendPendingReward(value.id)} className="btn btn-succes">Send</button>
                    </tr>

                );
            })

            let sended = this.state.withdraw.map((value, index) => {
                //let infoOfUser = await this.getUserInfo(value.winnerId);
                let dateof = new Date(Number(value.date)).toLocaleString()
                let isRevolut = value.isRevolut;
                let backgroundColor = "#48616d"
                if (isRevolut) {
                    backgroundColor = "#236888"
                }
                console.log(value)
                return (
                    <tr style={{ backgroundColor: backgroundColor }}>
                        <td><img src={value.userPhoto} alt="" /></td>
                        <td>{value.username}</td>
                        <td id={value.id}>{value.paypalEmail}</td>
                        <td>{value.withdrawBalance}</td>
                        <td>{value.requestedCurrency}</td>
                        {this.renderImageWithdraw(value.type)}
                        <td>{ }</td>
                        <button onClick={() => this.sendToWithdraw(value.id, value.username, value.paypalEmail)} className="btn btn-succes m-0">Send</button>
                        <button onClick={() => this.sendToWithdrawReturn(value.id, value.username, value.paypalEmail, path)} className="btn btn-succes m-0 ml-1">Return</button>
                        <button onClick={() => { this.copyClip(value.id, value.paypalEmail) }} className="btn btn-succes m-0 ml-1">Copy</button>
                        <button onClick={() => { }} className="btn btn-primary m-0 ml-1">{dateof}</button>


                        <td></td>
                    </tr>

                );
            })
            let sendedEarnMoney = this.state.withdrawEarnMoney.map((value, index) => {
                //let infoOfUser = await this.getUserInfo(value.winnerId);
                let dateof = new Date(Number(value.date)).toLocaleString()
                let isRevolut = value.isRevolut;
                let backgroundColor = "#48616d"
                if (isRevolut) {
                    backgroundColor = "#236888"
                }
                return (
                    <tr style={{ backgroundColor: backgroundColor }}>
                        <td><img src={value.userPhoto} alt="" /></td>
                        <td>{value.username}</td>
                        <td id={value.id}>{value.paypalEmail}</td>
                        <td>{value.withdrawBalance}</td>
                        <td>{value.requestedCurrency}</td>
                        {this.renderImageWithdraw(value.type)}
                        <td>{ }</td>
                        <button onClick={() => this.sendToWithdrawEarnMoney(value.id, value.username, value.paypalEmail)} className="btn btn-succes m-0">Send</button>
                        <button onClick={() => this.sendToWithdrawReturn(value.id, value.username, value.paypalEmail, pathEarnMoney)} className="btn btn-succes m-0 ml-1">Return</button>
                        <button onClick={() => { this.copyClip(value.id, value.paypalEmail) }} className="btn btn-succes m-0 ml-1">Copy</button>
                        <button onClick={() => { }} className="btn btn-primary m-0 ml-1">{dateof}</button>

                        <td></td>
                    </tr>

                );
            })
            let withdrawGameAndEarn = this.state.withdrawGameAndEarn.map((value, index) => {
                //let infoOfUser = await this.getUserInfo(value.winnerId);
                let dateof = new Date(Number(value.date)).toLocaleString()
                let isRevolut = value.isRevolut;
                let backgroundColor = "#48616d"
                if (isRevolut) {
                    backgroundColor = "#236888"
                }
                return (
                    <tr style={{ backgroundColor: backgroundColor }}>
                        <td><img src={value.userPhoto} alt="" /></td>
                        <td>{value.username}</td>
                        <td id={value.id}>{value.paypalEmail}</td>
                        <td>{value.withdrawBalance}</td>
                        <td>{value.requestedCurrency}</td>
                        {this.renderImageWithdraw(value.type)}
                        <td>{ }</td>
                        <button onClick={() => this.sendToWithdrawGameAndEarn(value.id, value.username, value.paypalEmail)} className="btn btn-succes m-0">Send</button>
                        <button onClick={() => this.sendToWithdrawReturn(value.id, value.username, value.paypalEmail, pathGameAndEarn)} className="btn btn-succes m-0 ml-1">Return</button>
                        <button onClick={() => { this.copyClip(value.id, value.paypalEmail) }} className="btn btn-succes m-0 ml-1">Copy</button>
                        <button onClick={() => { }} className="btn btn-primary m-0 ml-1">{dateof}</button>

                        <td></td>
                    </tr>

                );
            })
            let withdrawCashFun = this.state.withdrawCashFun.map((value, index) => {
                //let infoOfUser = await this.getUserInfo(value.winnerId);
                let dateof = new Date(Number(value.date)).toLocaleString()
                let isRevolut = value.isRevolut;
                let backgroundColor = "#48616d"
                if (isRevolut) {
                    backgroundColor = "#236888"
                }
                return (
                    <tr style={{ backgroundColor: backgroundColor }}>
                        <td><img src={value.userPhoto} alt="" /></td>
                        <td>{value.username}</td>
                        <td id={value.id}>{value.paypalEmail}</td>
                        <td>{value.withdrawBalance}</td>
                        <td>{value.requestedCurrency}</td>
                        {this.renderImageWithdraw(value.type)}
                        <td>{ }</td>
                        <button onClick={() => this.sendToWithdrawCashFun(value.id, value.username, value.paypalEmail)} className="btn btn-succes m-0">Send</button>
                        <button onClick={() => this.sendToWithdrawReturn(value.id, value.username, value.paypalEmail, pathCashFun)} className="btn btn-succes m-0 ml-1">Return</button>
                        <button onClick={() => { this.copyClip(value.id, value.paypalEmail) }} className="btn btn-succes m-0 ml-1">Copy</button>
                        <button onClick={() => { }} className="btn btn-primary m-0 ml-1">{dateof}</button>

                        <td></td>
                    </tr>

                );
            })
            let withdrawSite = this.state.withdrawSite.map((value, index) => {
                //let infoOfUser = await this.getUserInfo(value.winnerId);
                // console.log(new Date(Number(value.date)))
                let dateof = new Date(Number(value.date)).toLocaleString()
                console.log(dateof)
                let isRevolut = value.isRevolut;
                let backgroundColor = "#48616d"
                if (isRevolut) {
                    backgroundColor = "#236888"
                }
                return (
                    <tr style={{ backgroundColor: backgroundColor }}>
                        <td><img src={value.userPhoto} alt="" /></td>
                        <td>{value.username}</td>
                        <td id={value.id}>{value.paypalEmail}</td>
                        <td>{value.withdrawBalance}</td>
                        <td>{value.requestedCurrency}</td>
                        {this.renderImageWithdraw(value.type)}
                        <td>{ }</td>
                        <button onClick={() => this.sendToWithdrawSite(value.id, value.username, value.paypalEmail)} className="btn btn-succes m-0">Send</button>
                        <button onClick={() => this.sendToWithdrawReturn(value.id, value.username, value.paypalEmail, pathSite)} className="btn btn-succes m-0 ml-1">Return</button>
                        <button onClick={() => { this.copyClip(value.id, value.paypalEmail) }} className="btn btn-succes m-0 ml-1">Copy</button>
                        <button onClick={() => { }} className="btn btn-primary m-0 ml-1">{dateof}</button>

                        <span></span>
                        <td></td>
                    </tr>

                );
            })
            let waitingToBeSend = this.state.waitingToBeSend.map((value, index) => {
                //let infoOfUser = await this.getUserInfo(value.winnerId);
                // let iban = "";
                // if(value.IBAN){
                //     if(value.IBAN.split(' ').length == 1){
                //         if(value.IBAN.length > 10){
                //             var re = /[^0-9](?=[0-9])/g; 
                //             iban = value.IBAN.replace(re, '$& ')
                //             iban = iban.replace(re, '$& ');
                //         }
                //     }
                // }
                return (
                    <tr>
                        <td><img src={value.images[0]} alt="" /></td>
                        <td>{value.winnerName}</td>
                        <td>{value.phone ? value.phone : ""}</td>
                        <td>{value.adress ? value.adress : ""}</td>
                        <td>{value.IBAN ? value.IBAN : ""}</td>
                        <td>{value.epayKIN ? value.epayKIN : ""}</td>
                        <button onClick={() => this.sendPendingReward(value.id)} className="btn btn-succes">Send</button>
                    </tr>
                );
            })
            let giveawaysThatNeedDrawUrl = this.state.giveawaysThatNeedDrawUrl.map((value, index) => {
                return (
                    <div className="col-md-12 givieForDraw">
                        <div className="row">
                            <div className="col-md-5 ">
                                <span className="text-givies">{value.nameBG} - {value.winnerName}</span>
                            </div>
                            <div className="col-md-5">
                                <input className="inputUrl" type="text" placeholder="URL Youtube" onChange={this.setUrl}></input>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-confirm" onClick={() => {
                                    this.confirmDraw(value.id)
                                }}>Confirm</button>
                            </div>
                        </div>
                    </div>
                )
            })

            return (
                <div style={{}}>

                    <div className="table-users">

                        <div id="sended" className="header">Waiting To Be Send <b>Givvy</b></div>

                        <table cellspacing="0">
                            <tr>
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Paypal/Revolut</th>
                                <th>Request</th>
                                <th>Currency</th>
                                <th>Type payment</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            {sended}

                        </table>
                    </div>
                    <div className="table-users">

                        <div id="sended" className="header">Waiting To Be Send <b>Earn Money</b></div>

                        <table cellspacing="0">
                            <tr>
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Paypal/Revolut</th>
                                <th>Request</th>
                                <th>Currency</th>
                                <th>Type payment</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            {sendedEarnMoney}

                        </table>
                    </div>
                    <div className="table-users">

                        <div id="sended" className="header">Waiting To Be Send <b>Game & Earn</b></div>

                        <table cellspacing="0">
                            <tr>
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Paypal/Revolut</th>
                                <th>Request</th>
                                <th>Currency</th>
                                <th>Type payment</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            {withdrawGameAndEarn}

                        </table>
                    </div>
                    <div className="table-users">

                        <div id="sended" className="header">Waiting To Be Send <b>CashFun</b></div>

                        <table cellspacing="0">
                            <tr>
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Paypal/Revolut</th>
                                <th>Request</th>
                                <th>Currency</th>
                                <th>Type payment</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            {withdrawCashFun}

                        </table>
                    </div>
                    <div className="table-users">

                        <div id="sended" className="header">Waiting To Be Send <b>Givvy Offers</b></div>

                        <table cellSpacing="0">
                            <tr>
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Paypal/Revolut</th>
                                <th>Request</th>
                                <th>Currency</th>
                                <th>Type payment</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            {withdrawSite}

                        </table>
                    </div>
                    {/* <div className="container">
                        <div className="row">
                            {giveawaysThatNeedDrawUrl}
                        </div>
                    </div> */}

                </div>
            )
        } else {
            return (<div>loading...</div>)
        }
    }
}

export default Winners;