import React, { Component } from 'react';
import './Feed.css'
import axios from 'axios';
var socket;
var path = "https://giveybackend-prod.herokuapp.com/";
var user = { userId: '5dfe5030a0e1e23837500702' }
var deviceId = "dajslkdkjaslkjdlkasj";
class Feed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sizeStorie: 'col-2',
      finishedGivies: [],
      givies: [],
      currentGivies: [],
      visible: false,
      isLoading: false
    }
    // this.updateGivie = this.updateGivie.bind(this)
    // this.searchGivie = this.searchGivie.bind(this)
    // this.openModal = this.openModal.bind(this)
    // this.closeModal = this.closeModal.bind(this)
  }

  updateGivie(data) {
    let currentGivies = this.state.givies;
    let changedGivie = currentGivies.find(givie => givie.id === data.giveawayId);
    let numberOfGivie = currentGivies.indexOf(changedGivie);
    changedGivie.currentEntries = data.currentEntries;
    changedGivie.userEntries = data.userEntries;
    currentGivies.splice(numberOfGivie, 1);
    currentGivies.splice(numberOfGivie, 0, changedGivie);
    this.setState({ givies: currentGivies, currentGivies: currentGivies });

  }
  getData = () => {
    axios.post(`${path}giveawayList`, user)
      .then(res => {
        const givies = res.data.result.current;
        const finishedGivies = res.data.result.finished;
        this.setState({ givies, currentGivies: givies, finishedGivies: finishedGivies, isLoading: false });
      })
  }
  componentDidMount() {
    // this.getData();
    // socket = openSocket(path);
    // socket.on("connect", () => {
    //   socket.emit("join", { deviceId: deviceId, userId: user.userId });
    // });
    // socket.on('updateGiveaway', this.updateGivie)
    // window.addEventListener("resize", this.resize.bind(this));


  }
  resize() {
    this.setState({ sizeStorie: window.innerWidth <= 600 ? 'col-3' : 'col-2' });
  }

  searchGivie(e) {
    var targetSearch = e.target.value;

    this.setState({ currentGivies: this.state.givies.filter(f => f.name.toLowerCase().includes(targetSearch.toLowerCase())) });
  }
  openModal() {
    this.setState({
      visible: true
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }

  render() {
    let givies;
    let finishedGivies;
    if (!this.state.isLoading) {
      // finishedGivies = this.state.finishedGivies;
      // givies = this.state.currentGivies;
      // let printGivies = givies.map((givie, index) => {
      //   return (
      //     <div key={givie.id} className="col-6"><Givie modal={this.openModal} givie={givie} socket={socket}></Givie></div>
      //   )
      // })
      // let storiesGivies;
      // storiesGivies = finishedGivies.map((givie, index) => {
      //   if (index <= 5) {
      //     return (
      //       <div key={`${givie.id}-storie`} className='col-2'>
      //         <StoriesGivie modal={this.openModal} givie={givie}></StoriesGivie>
      //       </div>
      //     )
      //   } else {
      //     return (<div></div>);
      //   }
      // })

      // var style = {
      //   'border-radius': '25pt',
      // }

      return (
        <div className="">
          <div className="row">
            <div className="col-lg-6">
              <img id="new-phone" src="/money-no logo-01.png"></img>
              
            </div>
            <div id="second-phone" className="col-lg-6">
              <p>Play easy-peasy games and earn coins. Invite your friends and enjoy our awesome games.</p>
              {/* <a href='https://apps.apple.com/us/app/givvy-game/id1506358028'><img alt="jpg" id="logoIOS" src="/Group 614.png"></img></a>
              <a href='https://play.google.com/store/apps/details?id=com.givvy'><img alt="jpg" id="logoAndroid" src="/Group 778.png"></img></a> */}
              
            </div>
          </div>
        </div>
      );

      // return (
      //   <div className="container cont">
      //     {/* <InputGroup className="mb-3">
      //       <FormControl
      //         id="inputBar"
      //         placeholder="&#61442; Търсене"
      //         aria-label="Search"
      //         aria-describedby="basic-addon2"
      //         onChange={this.searchGivie}
      //       />
      //     </InputGroup> */}
      //     <div className="pop-up">
      //       {/* <ModalStores
      //         styles={{ borderRadius: '25pt', backgroundImage: 'linear-gradient(to top, #FF8181 -2%, #484D6D 110%)', border: '0 !important' }}
      //         visible={this.state.visible}
      //         width="1100"
      //         height="600"
      //         effect="fadeInDown"
      //         onClickAway={() => this.closeModal()}
      //       >
      //         <div>
      //           <img alt="jpg" id="logoAppStore" src="/Group 19.png"></img>
      //           <div className="textAppStore">
      //             <span>
      //               За да можете да използвате пълна функционалност на Givvy, е необходимо да го свалите от <span>Google Play Store</span>
      //               и <span>AppStore</span>
      //             </span>
      //           </div>
      //           <a href='https://apps.apple.com/us/app/givvy-game/id1506358028'><img alt="jpg" id="logoIOS" src="/Group 614.png"></img></a>
      //           <a href='https://play.google.com/store/apps/details?id=com.givvy'><img alt="jpg" id="logoAndroid" src="/Group 778.png"></img></a>
      //         </div>
      //       </ModalStores> */}
      //     </div>

      //     {finishedGivies.length ? <div>
      //       <span className="currentGiviesText"></span>
      //       <div className="row">
      //         {/* {storiesGivies} */}
      //       </div>
      //     </div> : <div></div>}
      //     <span className="currentGiviesText"></span>
      //     <div className="row">
      //       {/* {printGivies} */}
      //     </div>

      //   </div>
      // );
    } else {
      return (<div>Loading...</div>)
    }



    // if (isMobile) {
    //   let printGivies = givies.map((givie, index) => {
    //     return (
    //       <Givie givie={givie}></Givie>
    //     )
    //   })
    //   let storiesGivies = givies.map((value, index) => {
    //     if (index <= 3) {
    //       return (
    //         <div className='col-3'>
    //           <StoriesGivie name={value.name}></StoriesGivie>
    //         </div>
    //       )
    //     }
    //   })
    //   return (
    //     <div className="container">
    //       <InputGroup className="mb-3">
    //         <FormControl
    //           id="inputBar"
    //           placeholder="&#61442; Search"
    //           aria-label="Search"
    //           aria-describedby="basic-addon2"
    //         />

    //         <InputGroup.Append>
    //           <Button id="searchBar" variant="outline-secondary"><img src="/Group 267.png"></img></Button>
    //         </InputGroup.Append>
    //       </InputGroup>
    //       <div className="row">
    //         {storiesGivies}
    //       </div>
    //       {printGivies}

    //     </div>
    //   );
    // } else {
    //   
    // }
    //return (<div></div>);

  }
}

export default Feed;